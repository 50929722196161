import { mainStore } from '@/store';
import page from './page';
import { System, SysUserRole } from '@/libs/requestAddress';
import http from '@/libs/service/http.js';
import { tsTypes } from '@/libs/interfaceType';

export function routerMenu() {
  /*浏览器刷新或者第一次进入页面 get菜单 并且加上访问权限 成功继续进行 失败500*/
  return new Promise((resolve, reject) => {
    const store = mainStore();
    // 根据角色id 获取菜单列表
    if (!store.init) {
      http
        .get<Array<tsTypes.routerParams>>(`${SysUserRole.list}`)
        .then((list) => {
          if (Array.isArray(list) && list.length) {
            const { menuArr } = new GetMenuTree(list);
            const routerList = changeComponent(list);
            resolve({ menuArr, routerList });
          } else {
            const routerList: any[] = [];
            resolve({ routerList });
          }

          store.init = true;
        })
        .catch(() => {
          store.init = false;
          reject();
        });
    } else {
      resolve([]);
    }
  });
}

class GetMenuTree {
  menuArr: Array<tsTypes.routerParams> = [];
  constructor(list: Array<tsTypes.routerParams>) {
    this.init(list);
  }

  init(list: Array<tsTypes.routerParams>) {
    this.merge(list);
    return this.menuArr;
  }

  // 获取动态菜单
  merge(list: tsTypes.routerParams[]) {
    list.forEach((item) => {
      this.menuArr.push(this.getItem(item));
      if (item.children?.length) {
        this.merge(item.children);
      }
    });
  }
  getItem(item: tsTypes.routerParams): tsTypes.routerParams {
    return {
      path: `/${item.name}`,
      name: item.name,
      redirect: false,
      icon: 'md-menu',
      meta: {
        title: item.title,
        root: true, //访问权限
        keepAlive: false,
      },
      id: item.id,
      parentId: item.parentId,
      component: page[item.component],
      children: item.children,
    };
  }
}

function changeComponent(list: tsTypes.routerParams[]) {
  list.forEach((item) => {
    // item.component = page[item.component];
    // item.path = `/${item.name}`;
    // item.meta = {
    //   title: item.title,
    // };
    // if (item.children?.length) changeComponent(item.children);
    if (item.children?.length) {
      item.component = page['Main'];
      item.path = `/${item.name}`;
      item.meta = {
        title: item.title,
      };
      changeComponent(item.children);
    } else {
      if (item.parentId) {
        item.component = page[item.component];
        item.path = `/${item.name}`;
        item.meta = {
          title: item.title,
        };
      } else {
        item.path = '/';
        item.redirect = `/${item.name}`;
        item.meta = {
          title: item.title,
        };
        item.component = page['Main'];
        item.children = [
          {
            id: item.id,
            parentId: 0,
            name: item.name,
            path: `/${item.name}`,
            title: item.title,
            component: page[item.name as string],
            meta: {
              title: item.title,
            },
          },
        ];
      }
    }
  });
  console.log(list);
  return list;
}

<template>
  <div class="content">
    <div class="main">
      <img src="@/assets/images/noPower/p2.png" alt="" />
      <div>
        <h3>账号无权限</h3>
        <p>抱歉，您的账号无任何权限，请联系管理员添加</p>
        <div @click="reload">重新登录</div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  const router = useRouter();
  const reload = () => {
    sessionStorage.clear();
    localStorage.clear();
    router.replace({
      path: '/login',
      replace: true,
    });
  };
</script>

<style scoped lang="less">
  .content {
    width: 100%;
    height: 100vh;
    background-image: url('@/assets/images/noPower/p1.png');
    background-size: 446px 375px;
    background-color: #f0f3f7;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .main {
      display: flex;
      > img {
        width: 438px;
        height: 268px;
        margin-right: 60px;
      }
      > div {
        display: flex;
        flex-flow: column;
        justify-content: center;
        > h3 {
          height: 64px;
          font-size: 30px;
          font-weight: bold;
          color: #000000;
          line-height: 64px;
        }
        > p {
          height: 28px;
          font-size: 20px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.45);
          line-height: 28px;
          margin: 10px 0 45px;
        }
        > div {
          width: 100px;
          height: 40px;
          background: #007bff;
          border-radius: 8px;
          font-size: 16px;
          font-weight: 400;
          color: #ffffff;
          line-height: 40px;
          text-align: center;
          cursor: pointer;
        }
      }
    }
  }
</style>

/* 公共接口 */
export const Common = {
  fileUpload: `/upload/image`, // 图片上传
  videoUpload: `/upload/video`, // 视频上传
  pdfUpload: `/upload/pdf`, // 视频上传
  apkUpload: `/upload/apk`, // APK上传
  excelUpload: `/upload/excel`, // excel上传
  getCityALLData: `/channel/cityConstant/list`,
  selectMetadataField: '/selectMetadataField', // 元数据查询
};

/* 用户权限 */
export const Auth = {
  sendSms: `/login/sms.e`,
  login: `/login/login.e`,
};

/* 首页 */
export const Home = {
  getHomeCard_api: `/count/home/card`, // 卡片列表
  getHomePage_api: `/count/home/page`, // 分页统计
};

/* 渠道管理 */
// 渠道分组
export const ChannelGroup = {
  pageList: '/channelGroup/channel',
  list: '/channelGroup/list',
  selectById: '/channelGroup/selectById',
  update: '/channelGroup/update',
  insert: '/channelGroup/insert',
};
// 渠道列表
export const Channel = {
  pageList: `/channel/pageList`,
  detail: `/channel/detail`,
  insert: `/channel/insert`,
  update: `/channel/update`,
  selectChannel: `/channel/selectChannel`, // 渠道下拉列表查询 get
};

// 注册页管理
export const ChannelRegisterPage = {
  list: '/channelRegisterPage/list', // get
  pageList: '/channelRegisterPage/channel', // get
  selectById: '/channelRegisterPage/selectById', // get
  update: '/channelRegisterPage/update', // post
  insert: '/channelRegisterPage/insert', // post
  delete: '/channelRegisterPage/delete', // {id} post
};

/* 系统管理 */
export const System = {
  /* 系统用户 */
  sysUserPage: '/sys/user/page', // 用户列表查询 get
  sysUserInsert: '/sys/user/insert', // 用户新增 post
  sysUserUpdate: '/sys/user/update', // 用户修改 post
  sysUserDetail: '/sys/user/detail', // 用户详情查询 get
  sysUserRole: '/sys/userRole', // 修改用户角色 post  用户已有角色列表 get
  /* 角色 */
  sysRoleInsert: '/sys/role/insert', // 用户角色保存 post
  sysRoleUpdate: '/sys/role/update', // 用户角色新增 post
  sysRoleDelete: '/sys/role/delete', // 角色 删除 post {id}
  sysRole: '/sys/role', // 基础权限树获取 get
  sysRoleAndMenu: '/sys/roleandmenu', // 添加角色-菜单关联关系 post
  sysRoleDetail: '/sys/role/detail', // 角色详情获取 get
  /* 菜单 */
  sysMenuInsert: '/sys/menu/insert', // 菜单保存 post
  sysMenuUpdate: '/sys/menu/update', // 菜单修改 post
  sysMenuList: '/sys/menu/list', // 查询菜单树（有roleId时会携带是否已选择菜单的结果） get
  sysSelectUserMenus: '/sys/selectUserMenus', // 获取用户已有菜单树 get
  sysMenu: '/sys/menu', // 菜单删除 post {id}
  sysMenuDetail: '/sys/menu/detail', // 查询菜单详情 get
  sysRoleMenuList: '/sys/roleMenu/list', // 查询角色已有菜单列表 get
  /* 客户派发规则 */
  sysDistributePage_api: '/sellConfig/pageList', // 列表查询 get
  sysDistributeDetail_api: '/sellConfig/detail', // 客户派发规则详情 post
  sysDistributeUpdate_api: '/sellConfig/update', // 客户派发规则编辑 post
  /*客户标签管理*/
  sysTagPageList: '/tag/pageList', //标签列表
  sysTagTreeList: '/tag/tagGroupList', //标签树形结构-无系统预定义
  sysTagAddGroup: '/tag/addTagGroup', //增加标签组
  sysTagUpdateGroup: '/tag/updateTagGroup', //更新标签组
  sysTagAdd: '/tag/addTag', //新增标签
  sysTagUpdate: '/tag/updateTag', //更新标签
  sysTagDelGroup: '/tag/delTagGroup', //删除标签组
  sysTagDel: '/tag/delTag', //删除标签
  sysTagTree: '/tag/treeList', //标签树
};

/* 用户管理 */
export const User = {
  page: `/user/page`,
  detail: `/user/detail`,
  logOff: `/user/logOff`,
};

/* 贷超管理 */
export const Product = {
  pageList: '/product/pageList',
  detail: '/product/detail',
  insert: '/product/insert',
  update: '/product/update',
  updateStatus: '/product/updateStatus',
  productAudit: '/product/productAudit',
  queryAgencyCity: '/product/queryAgencyCity',
  dayPage: '/product/day/page',
  del: '/product/del',
  restoreDel: '/product/restoreDel',
  delRecord: '/product/delRecord',
  restoreProduct: '/product/restoreProduct',
  cityData: '/product/cityData',
};

// 权限组管理
export const SysGroup = {
  list: '/sysGroup/list',
  selectById: '/sysGroup/selectById',
  insert: '/sysGroup/insert',
  update: '/sysGroup/update',
  delete: '/sysGroup/delete',
  menuTree: '/sysGroup/menu/tree',
};
// 用户已有权限
export const SysUserRole = {
  list: '/sysUserRole/list',
  auths: '/sysUserRole/auths', // 用户当前菜单所有权限查询 get
};
// 员工管理
export const SysUser = {
  page: '/sysUser/page',
  selectById: '/sysUser/selectById',
  update: '/sysUser/update',
  insert: '/sysUser/insert',
  delete: '/sysUser/delete',
  allList: '/sysUser/allList',
  list: '/sysUser/list',
  transferPageList: '/sysUser/transfer/pageList',
  transferDetail: '/sysUser/transfer/detail',
  transferData: '/sysUser/transfer/data',
};
// 部门管理
export const SysRole = {
  list: '/sysRole/list',
  selectById: '/sysRole/selectById',
  update: '/sysRole/update',
  insert: '/sysRole/insert',
  delete: '/sysRole/delete',
};

// 交易记录
export const TransactionRecords = {
  getTransRecordCard_api: `/agencyTransRecord/card`, // 交易记录卡片查询
  getTransRecordRecharge_api: `/agencyTransRecord/recharge/page`, // 充值记录分页查询
  getTransRecordReduce_api: `/agencyTransRecord/reduce/page`, // 消费记录分页查询
  pay: '/pay', // 支付
  payStatus: '/payStatus', // 支付状态
};

// 客户总库
export const CustomWarehouse = {
  getAllCate_api: '/customer/updateAllocate', // 修改是否开启自动派发状态
  getQueryAllCate_api: '/customer/selectAllocateDetail', // 查询是否开启了自动派发状态
  getTableList_api: '/customer/pageList', // 客户管理列表分页查询
  getDetail_api: '/customer/detail', // 客户管理详情查询
  checkAuth: '/customer/checkAuth', // 查询是否有查看客户管理详情的权限
  postUpdate_api: '/customer/update', // 客户管理编辑
  postFollowUpRecord_api: '/customer/insertFollowUpRecord', // 客户管理新增跟进记录
  getDispatchOrders_api: '/customer/dispatchOrders', // 订单派发
  getQueryPhone_api: '/customer/queryPhone', // 手机号明文查询
  sendSms: '/customer/sendSms', // 发送提醒短信
  findSmsTemplate: '/customer/findSmsTemplate', // 查询发送提醒短信模板
  findSmsSendResult: '/customer/findSmsSendResult', // 查询发送进度
};

// 发布公告
export const Agency = {
  pageList: '/agency/notice/pageList', // 分页查询
  insert: '/agency/notice/insert', // 新增
  update: '/agency/notice/update', // 修改
  detail: '/agency/notice/detail', // 详情
  delete: '/agency/notice/delete', // 删除
};

// 首页系统管理
export const Notice = {
  pageList: '/notice/record/pageList', // 用户通知列表查询
  detail: '/notice/record/detail', // 用户通知详情
  getNotice: '/notice/record/selectNotice', // 实时获取用户通知
  readAll: '/notice/record/readAll', // 全部已读
  configDetail: '/notice/config/detail', // 配置详情
  updateConfig: '/notice/config/update', // 配置修改
};

// 系统管理-操作日志
export const OperationLog = {
  pageList: '/sys/operationLog/pageList',
};

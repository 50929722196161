import md5 from 'js-md5';
import { tsTypes } from '@/libs/interfaceType';
import api from '@/libs/api';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import CryptoJS from 'crypto-js';
import { RouteRecordRaw } from 'vue-router';

const basicsMethods = {
  // 统一处理formlist
  unifiedListFindValue(list: Array<tsTypes.ParamsUnKnown>, value: number | string, key: string) {
    return (
      list.find((item) => {
        return item[key] === value;
      }) || { text: '-' }
    );
  },
  // 从字典中查询对应的文本
  findText(
    list: Array<tsTypes.ParamsUnKnown>, // 字典列表
    value: number | string | boolean, // 值
    textKey = 'text', // 文本的key
    valueKey = 'value' // 值得key
  ) {
    const _find = list.find((item) => item[valueKey] === value);
    return _find ? _find[textKey] : '';
  },
  // 签名
  createRequestSign(
    data: tsTypes.ParamsUnKnown = {},
    timestamps?: number | string,
    method?: string
  ): string {
    const keyList = Object.keys(data);
    let urlParams = '';
    if (method === 'get') {
      for (const key of keyList) {
        urlParams +=
          keyList[keyList.length - 1] !== key
            ? `${key}=${encodeURI(<string>data[key])}&`
            : `${key}=${encodeURI(<string>data[key])}`;
      }
    } else {
      urlParams = JSON.stringify(data);
    }
    urlParams += `${timestamps}${api.SIGNKEY}`;
    // console.log('--------urlParams-->', urlParams);
    return urlParams;
  },

  mathMethod: {
    // 乘法
    multiply(arg1: number, arg2: number) {
      let m = 0;
      try {
        const arg1Fractional = String(arg1).split('.')[1];
        const arg2Fractional = String(arg2).split('.')[1];
        m += (arg1Fractional && arg1Fractional.length) || 0;
        m += (arg2Fractional && arg2Fractional.length) || 0;
      } catch (e) {
        return NaN;
      }
      return (
        (Number(String(arg1).replace('.', '')) * Number(String(arg2).replace('.', ''))) /
        Math.pow(10, m)
      );
    },
    // 除法
    division(arg1: number, arg2: number) {
      try {
        const arg1Fractional = String(arg1).split('.')[1];
        const arg2Fractional = String(arg2).split('.')[1];
        const arg1Length = (arg1Fractional && arg1Fractional.length) || 0;
        const arg2Length = (arg2Fractional && arg2Fractional.length) || 0;
        const differenceMultiple = Math.pow(10, arg2Length - arg1Length);
        return this.multiply(
          Number(String(arg1).replace('.', '')) / Number(String(arg2).replace('.', '')),
          differenceMultiple
        );
      } catch (e) {
        return NaN;
      }
    },
    // 加法
    addition(arg1: number, arg2: number) {
      try {
        const arg1Fractional = String(arg1).split('.')[1];
        const arg2Fractional = String(arg2).split('.')[1];
        const arg1Length = (arg1Fractional && arg1Fractional.length) || 0;
        const arg2Length = (arg2Fractional && arg2Fractional.length) || 0;
        const expandedMultiplier = Math.pow(10, Math.max(arg1Length, arg2Length));
        return (
          (this.multiply(arg1, expandedMultiplier) + this.multiply(arg2, expandedMultiplier)) /
          expandedMultiplier
        );
      } catch (e) {
        return NaN;
      }
    },
    // 减法
    subtraction(arg1: number, arg2: number) {
      try {
        const arg1Fractional = String(arg1).split('.')[1];
        const arg2Fractional = String(arg2).split('.')[1];
        const arg1Length = (arg1Fractional && arg1Fractional.length) || 0;
        const arg2Length = (arg2Fractional && arg2Fractional.length) || 0;
        const expandedMultiplier = Math.pow(10, Math.max(arg1Length, arg2Length));
        return (
          (this.multiply(arg1, expandedMultiplier) - this.multiply(arg2, expandedMultiplier)) /
          expandedMultiplier
        );
      } catch (e) {
        return NaN;
      }
    },
  },

  // 将数值变为tree的结构
  treeDataClean: (items: Array<tsTypes.objAndAny>, fatherId: 0) => {
    const result = []; // 存放结果集
    const itemMap: tsTypes.objAndAny = {}; //
    for (const item of items) {
      const id = item.id;
      const pid = item.pid;

      if (!itemMap[id]) {
        itemMap[id] = {
          children: [],
        };
      }

      itemMap[id] = {
        ...item,
        children: itemMap[id]['children'],
      };

      const treeItem = itemMap[id];

      if (pid === fatherId) {
        result.push(treeItem);
      } else {
        if (!itemMap[pid]) {
          itemMap[pid] = {
            children: [],
          };
        }
        itemMap[pid].children.push(treeItem);
      }
    }
    return result;
  },

  /**
   *金钱格式化
   *{param:number} s 金额
   *{param:number } n 保留几位小数
   *eg:this.fmoney(1342684.316,2) // 1,342,684.32
   */
  formatMoney: (s: string, n: number) => {
    s = parseFloat((s + '').replace(/[^\d\.-]/g, '')).toFixed(n) + '';
    const l = s.split('.')[0].split('').reverse();
    let t = '';
    for (let index = 0; index < l.length; index++) {
      t += l[index] + ((index + 1) % 3 == 0 && index + 1 != l.length ? ',' : '');
    }
    let result = t.split('').reverse().join('');
    if (n) {
      const r = s.split('.')[1];
      result += '.' + r;
    }
    return result;
  },
  // 递归
  recursion: (
    arr: tsTypes.objAndAny[],
    children = 'children',
    resultArr: tsTypes.objAndAny[]
  ): tsTypes.objAndAny[] => {
    arr.forEach((item: tsTypes.objAndAny) => {
      if (item[children] && item[children].length) {
        basicsMethods.recursion(item[children], children, resultArr);
      } else {
        resultArr.push(item);
      }
    });
    console.log(resultArr);
    return resultArr;
  },
};

export default basicsMethods;

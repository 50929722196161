import type { Directive, DirectiveBinding } from 'vue';
import { mainStore } from '@/store';

export const permission: Directive = {
  mounted(el: HTMLElement, binding: DirectiveBinding) {
    const { value } = binding;
    if (value) {
      const store = mainStore();
      const permissions: string[] = store.userPageAuth;
      const has = permissions.some((item) => item === value);
      if (!has) el.parentNode && el.parentNode.removeChild(el);
    }
  },
};
export default permission;

<template>
  <router-view />
  <PreviewImages></PreviewImages>
  <!-- S 消息弹窗 -->
  <NoticeDialog v-if="store.showNoticeDialog" />
  <!-- E -->
</template>

<script setup lang="ts">
  import PreviewImages from '@/components/previewImages/index.vue';
  import NoticeDialog from '@/views/home/components/noticeDialog.vue';
  import api from '@/libs/api';

  import { mainStore } from '@/store';
  const store = mainStore();
  // export default {
  //   name: 'App',
  //   components: {
  //     PreviewImages,
  //   },
  //   mounted() {
  //     let link = window.document.querySelector("link[rel*='icon']") as any;
  //     if (link?.href) link.href = api.logo;
  //   },
  // };
  onMounted(() => {
    // 原来的根据环境更换网页icon
    let link = window.document.querySelector("link[rel*='icon']") as any;
    if (link?.href) link.href = api.logo;

    // 轮询请求系统通知
    store.getNoticeList();
  });
</script>

/**
 * @author: zhangyh-k@glondon.com
 * @description:
 * @Date: 2020/1/14 22:08
 */
!(function f() {
  let wH = window.innerHeight; // 当前窗口的高度
  let wW = window.innerWidth; // 当前窗口的宽度
  let whdef = 100 / 1920; // 表示1920的设计图,使用100PX的默认值
  if (wW > 1400) {
    let rem = wW * whdef; // 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
    let html = document.documentElement;
    html.style.fontSize = rem + 'px'; //适用于PC网站
  } else {
    let rem = 1400 * whdef;
    let html = document.documentElement;
    html.style.fontSize = rem + 'px';
  }
})();

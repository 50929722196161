interface page {
  [key: string]: any;
}

export default {
  Main: () => import('@/components/main/main.vue'),
  home: () => import('@/views/home/home.vue'),

  /* 产品管理 */
  productList: () => import('@/views/productManagement/productList/productList.vue'), // 助贷产品

  /* 客户管理 */
  serviceList: () => import('@/views/customManage/customManage/customManage.vue'), // 客户总库
  departTransfer: () => import('@/views/customManage/departTransfer/departTransfer.vue'), // 离职转移

  /* 财务管理 */
  tradingRecord: () => import('@/views/financeManagement/tradingRecord/tradingRecord.vue'), // 交易记录

  /* 系统管理 */
  channel: () => import('@/views/systemManagement/channel/channel.vue'), // 渠道管理
  customerDistributionRule: () =>
    import('@/views/systemManagement/customerDistributionRule/customerDistributionRule.vue'), // 客户派发规则
  staffManagement: () => import('@/views/systemManagement/staffManagement/staffManagement.vue'), // 员工管理
  rightsGroup: () => import('@/views/systemManagement/rightsGroup/rightsGroup.vue'), // 权限组管理
  customLabelManage: () =>
    import('@/views/systemManagement/customLabelManage/customLabelManage.vue'), // 客户标签管理
  operationLog: () => import('@/views/systemManagement/operationLog/operationLog.vue'), // 操作日志
  releaseNotice: () => import('@/views/systemManagement/releaseNotice/releaseNotice.vue'), // 发布公告
} as page;

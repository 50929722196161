<template>
  <login-form @on-success-valid="handleSubmit"></login-form>
</template>

<script lang="ts">
  import LoginForm from '_c/login-form';
  import adminConfig from '@/libs/config.js';
  import basics from '@/libs/basics';
  import http from '@/libs/service/http.js';
  import { Auth } from '@/libs/requestAddress';

  export default {
    components: {
      LoginForm,
    },
    setup() {
      const router = useRouter();
      const handleSubmit = (user: { phone: string; smsCode: string; isMaster: number }) => {
        http.post(Auth.login, user).then((res) => {
          basics.Storage.setLocal(adminConfig.store.authorCacheName, res);
          router.replace({
            path: '/',
          });
        });
      };

      return {
        handleSubmit,
      };
    },
  };
</script>

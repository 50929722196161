import logo_ from '@/assets/images/logo.png';
import logoTitle_ from '@/assets/images/logo-title.png';

const runType = import.meta.env.APP_FLAG;
let apiPrefixPath = ''; // 暂未使用
let SIGNKEY = '';
const logo = logo_;
const logoTitle = logo_;
const title = '荟来单';
export let ossPath = ''; // 动态域名OSS地址

/*
 * TODO 需要更换接口时需要修改的地方
 * */
switch (runType) {
  case 'pro': // 线上环境
    apiPrefixPath = '';
    ossPath = 'https://hxqb-pro.oss-cn-chengdu.aliyuncs.com/open/domain';
    SIGNKEY = 'pk1ZD4sbbCfniFQzWiYRRNddt7h3fQyk';
    break;
  case 'test': // 外网测试环境
    apiPrefixPath = '';
    ossPath = 'https://hxqb-test.oss-cn-chengdu.aliyuncs.com/open/domain';
    SIGNKEY = 'pk1ZD4sbbCfniFQzWiYRRNddt7h3fQyk';
    break;
  default: // 默认环境
    apiPrefixPath = ''; // http://192.168.0.102:11000/admin  http://118.31.165.17:11000/admin
    ossPath = 'https://hxqb-pro.oss-cn-chengdu.aliyuncs.com/open/domain';
    SIGNKEY = 'pk1ZD4sbbCfniFQzWiYRRNddt7h3fQyk';
    break;
}
export default {
  apiPrefixPath,
  SIGNKEY,
  ossPath,
  logo,
  logoTitle,
  title,
};
